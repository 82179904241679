import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Box, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import { Chip } from '@mui/material';

const FilterableTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(`/assets/custom/works_order/new.php?pagination[page]=1&pagination[perpage]=10&query=`);

        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data: ", error); 

      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filtered data based on filterText
  const filteredData = data.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  );

  // Define columns for the DataGrid
  const columns = [
    { field: 'SN', headerName: 'SN', width: 70,headerAlign: 'center', align: 'center' },
    { field: 'WorksOrder', headerName: 'Works Order', width: 150,headerAlign: 'center', align: 'center' },
    { field: 'PartNo', headerName: 'Part No', width: 150,headerAlign: 'center', align: 'center' },
    { field: 'Product', headerName: 'Product', width: 200,headerAlign: 'center' },
    { field: 'Size', headerName: 'Size', width: 200,headerAlign: 'center', align: 'center' },
    {
      field: 'Priority',
      headerName: 'Priority',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.value === '1' ? 'High' : params.value === '2' ? 'Normal' : 'Unknown'}
          color={params.value === '1' ? 'success' : 'primary'}
          variant="outlined"
        />
      ),
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.value === '0' ? 'Open' : params.value === '1' ? 'Closed' : 'Unknown'}
          color={params.value === '0' ? 'success' : 'error'}
          variant="outlined"
        />
      ),
    },
    { field: 'Quantity', headerName: 'Quantity', width: 100,headerAlign: 'center', align: 'right' },
    { field: 'Customer', headerName: 'Customer', width: 250,headerAlign: 'center' },
    { field: 'IssueSlipNo', headerName: 'Issue Slip No', width: 150,headerAlign: 'center', align: 'center' },
  ];

  return (
    <Box sx={{ ml: 7,p: 2 }}>
      <Divider sx={{ mb: 3 }} />

      <TextField
        label="Filter"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Type to filter results"
      />

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 400 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={filteredData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
          disableSelectionOnClick
          getRowId={(row) => row.id} // Ensure unique row ID based on `id` field
        />
      )}
    </Box>
  );
};

export default FilterableTable;
