// import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Sidebar from './components/sidebar/Sidebar';
import WorksOrderForm from './pages/works_order_management/works_order_management_form';
import { Box } from '@mui/material';
import FilterableTable from './pages/works_order_management/works_order_management_table';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  typography: {
    fontFamily: 'ISOCpIV50, sans-serif', // Set the default font family
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Header />
          <Sidebar />
          <Box
            component="main"
            sx={{
              flex: 1, // This pushes the footer down when content is short
            }}
          >
            <WorksOrderForm />
            <FilterableTable />
          </Box>
          <Footer />
        </Box>
      </div>
    </ThemeProvider>
  );
}


