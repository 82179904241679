import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';

const Header = () => {
  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#484848' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, ml:7 }}>
          <img
            src="https://hcc.hatimerp.in/assets/media/company-logos/logo.png"
            alt="Company Logo"
            style={{ width: 40, height: 40, marginRight: 8 }}
          />
          <Typography variant="h6" component="div">
            HATIM CARBON COMPANY
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
